<template>
    <SectionCard
        v-if="!is_manager && listData.length || is_manager"
        title="筹款动态"
        class="publish-dynamic">
        <PublishDynamic v-if="is_manager"/>

        <DynamicList
            :listData="listData"
            :loadState="loadState"
            @loadMore="getListData"
        />
    </SectionCard>
</template>

<script>
import { getDynamicList } from '../../js/api';
import { mapState } from 'vuex';
import SectionCard from '../SectionCard/index.vue';

import utils from "@/common/js/utils";
const urlParams = utils.getRequestParams();

export default {
    name:'ProjectDynamic',
    data () {
        return {
            listData: [],
            page: 1,
            loadState: 'loading',
            list_total: 0,
        }
    },
    computed: {
        ...mapState(['is_manager']),
    },
    components: {
        SectionCard,
        PublishDynamic:() => import('./PublishDynamic.vue'),
        DynamicList:() => import('./DynamicList.vue'),
    },

     created() {
        this.getListData();
    },

    methods: {
        getListData() {
            const params = {
                projuuid: urlParams.projuuid,
                page: this.page,
            };

            this.loadState = 'loading';
            getDynamicList(params).then(res => {
                if (res.code == 0) {
                    let data = res.data;
                    let currentList = data.list;

                    this.list_total = data.total;
                    if (data.list && data.list.length) {
                        this.listData.push.apply(this.listData, currentList);
                    }

                    if (!currentList || !currentList.length || this.list_total === this.listData.length) {
                        this.loadState = 'noMore';
                    } else {
                        this.loadState = 'more';
                        this.page++;
                    }

                    this.$emit('changeDynamicData', this.listData)
                } else {
                    alertMsg(res.msg);
                    this.loadState = 'noMore';
                }
            });
        },
    }
}
</script>

<style lang='less' scoped>
</style>
